.App {
  text-align: center;
  padding: 0 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgb(34, 196, 241);
}
input {
  padding-left: 4px;
  color: #282c34;
}
.container {
  max-width: 320px;
  margin: 16px auto;
  padding: 16px;
  border: 1px solid rgb(3, 115, 227);
  border-radius: 4px;
}
.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  color: #353434;
}
.title-descriptions {
  color: rgb(235, 6, 6);
  margin-bottom: 24px;
}
.control {
  margin-bottom: 24px;
}
.input-container {
  display: flex;
  flex-direction: column;
}
.helper-text {
  margin-top: 4px;
  text-align: left;
  font-size: 12px;
  color: rgb(224, 57, 57);
}

.password-container {
  position: relative;
}
.toggle-password {
  position: absolute;
  right: 10px;
  top: 18px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: url('../public/eye.png') no-repeat center center;
  background-size: contain;
}
#password {
  padding-right: 30px; /* Adjust the padding to make space for the toggle button */
}
.button {
  width: 100%;
  height: 36px;
  margin-top: 32px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  background-color: rgb(3, 115, 227);
  border-radius: 4px;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.button-disabled {
  cursor: default;
  opacity: 0.5;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
